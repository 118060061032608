import React from "react"
import Layout from "../../components/layout"
import 'swiper/css/swiper.css';
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import PageIntro from "../../components/pageintro"
import { Link } from "gatsby"


const ListingPage = ({ data }) => (
  <Layout>
    <SEO title="The Staycation Destinations" description="Never been beyond your house and the airport? Don't worry we've got you sorted." />
    <h1>Listings Index</h1>
    <div style={{ marginBottom: `1.45rem` }}>
    <PageIntro>
    <p>Why are you even on this page?</p>
    <p>Browse these {data.allMarkdownRemark.totalCount} listings below</p>
    </PageIntro>
    <ul>
    {data.allMarkdownRemark.edges.map(({ node }) => (
          <li>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
    </ul>
    </div>
  </Layout>
)

export default ListingPage

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "listing"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`